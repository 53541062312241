.block-ui-overlay{
    background: #333;
    opacity: 0.6;
}
.block-ui{
    height: 100%;
}
.block-ui-message{
    font-size: 1.25em;
    color: #fff;
}
.loading-bullet{
    color: #fff;
}